body {
  background-color: #0F0F0F;
}

textarea:focus,
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

@media (max-width:768px) {
  .title-test {
    width: 95%;
    text-align: center;
    height: 45px;
    left: 45px;
    top: 41px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    padding-top: 17px;
    line-height: 45px;

    color: #19FB80;
    background-color: #0F0F0F;
  }

  .address-input {
    box-sizing: border-box;

    position: absolute;
    height: 60px;
    left: 5%;
    right: 5%;
    top: 43%;
    width: 90%;
    padding: .75em;
    background: #222725;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;

    /* identical to box height */
    display: flex;
    align-items: center;

    color: #FFFFFF;
  }

  .instructions {
    /* Paste your Address */
    justify-content: center;

    left: 5%;
    width: 90%;
    position: absolute;
    top: calc(43% + 80px);

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;

    /* identical to box height */
    display: flex;
    align-items: center;
    margin: 0 auto;

    color: #FFFFFF;
  }

}

@media (min-width:768px) {
  .title-test {
    position: absolute;
    width: 100%;
    height: 45px;
    left: 45px;
    top: 41px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 3em;
    line-height: 45px;

    /* identical to box height */
    display: flex;
    align-items: center;

    color: #19FB80;
  }

  .address-input {
    box-sizing: border-box;

    position: absolute;
    height: 60px;
    left: 30%;
    right: 30%;
    top: 43%;
    width: 40%;
    padding: .75em;
    background: #222725;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;

    /* identical to box height */
    display: flex;
    align-items: center;

    color: #FFFFFF;
  }

  .instructions {
    /* Paste your Address */
    justify-content: center;

    position: absolute;
    left: 30%;
    right: 30%;
    top: calc(43% + 80px);

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;

    /* identical to box height */
    display: flex;
    align-items: center;
    margin: 0 auto;

    color: #FFFFFF;
  }
}
